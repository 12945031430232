import React from 'react' ;
import './App.css' ;
import Card from './components/Card';
import privacy from './privacy'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

export default function App () {
    return (
    <>
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Card name={'Andreas R. Völker'}
                                                         about={'I have chosen to live this way.'}
                                                         job={'Love it, Change it, or leave it.'}
                                                         standardUsername={'idna001'}
                                                         secondUsername={'idna03'}
                                                         thirdUsername={'andreas-voelker'}
                                                         mobileNumber={'+491701110607'}
                                                         mail={'voelker_andreas@icloud.com'}
                                                         profilePic={'https://avatars.githubusercontent.com/u/46223359?v=4'}
                    />} />
                    <Route path="/privacy" element={privacy()} />

                </Routes>
                <Link className={"privacy-button"} to="/privacy">Privacy</Link>
            </div>
        </Router>
    </>
    );
}
