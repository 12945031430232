import React from 'react'
import './Card.css'

function Card ({
   name,
   job,
   about,
   profilePic

}) {
    return (
        <div className='Card'>
            <div className='upper-container'>
                <div className='image-container'>
                    <img src={profilePic} alt="Github profilepicture" />
                </div>
            </div>
            <div className="lower-container">
                <h3> {name} </h3>
                <h4> {job} </h4>
                <p> {about} </p>
            <div>
                <a href="http://www.instagram.com/idna001" target="_blank" rel="noreferrer"><img src={require('../img/instagram.png')} alt="instagram-logo" height="30px" width="30px"/></a>
                <a href="http://snapchat.com/add/idna03" target="_blank" rel="noreferrer"><img src={require('../img/snapchat.webp')} alt="snapchat-logo" height="30px" width="30px"/></a>
                <a href="https://linkedin.com/in/andreas-voelker" target="_blank" rel="noreferrer"><img src={require('../img/linkedin.webp')} alt="linkedin-logo" height="30px" width="30px"/></a>
                <a href="https://twitter.com/idna001" target="_blank" rel="noreferrer"><img src={require('../img/twitter.webp')} alt="twitter-logo" height="30px" width="30px"/></a>
                <a href="https://fb.com/idna001" target="_blank" rel="noreferrer"><img src={require('../img/facebook.png')} alt="facebook-logo" height="30px" width="30px"/></a>
            </div>
            <div>
                <a href="https://wa.me/+491701110607"><img src={require('../img/whatsapp.png')} alt="whatsapp-logo" height="33px" width="33px"/></a>
                <a href="https://github.com/idna001"><img src={require('../img/github.png')} alt="github-logo" height="33px" width="33px"/></a>
                <a href="tel:+49170111607"><img src={require('../img/telefon.png')} alt="telefon-logo" height="30px" width="30px"/></a>
                <a href="mailto:voelker_andreas@icloud.com?subject=Anfrage aus Visitenkarte&body=Hallo Andreas"><img src={require('../img/mail.png')} alt="mail-logo" height="30px" width="30px"/></a>
            </div>
                <div>
                    <button><a href={require('../contact-information/Andreas  Voelker .vcf')} rel="noreferrer" target="_blank">Add to contacts</a></button>
                </div>
            </div>
        </div>
        )
}

export default Card